import type { FetchContext } from 'ofetch';
import { RespCode } from './config';
import { message } from 'ant-design-vue';
import { ResponseMessage } from '~/utils/request';
import { isNil } from 'lodash-es';
import type { ApiHandler } from './types';

export function createHandlers(): ApiHandler {
  return {
    async onRequest({ options }: FetchContext) {
      const headers: HeadersInit = new Headers();
      const cookie = getUToken();
      if (cookie) {
        headers.append('utoken', cookie);
      }
      options.headers = headers;
    },
    async onResponse({ response }: FetchContext) {
      if (import.meta.client) {
        const nuxtApp = getNuxtApp();
        if (isNil(nuxtApp)) {
          console.warn('[api]: nuxtApp is nil');
        }
        const code = response?._data.code;
        if (code === RespCode.TOKEN_EXPIRED && !isNil(nuxtApp)) {
          const { $client } = nuxtApp;
          // 未打开登录弹窗时
          if (!document.querySelector('#passport-modal-container')) {
            $client.logout();
            $client.openModal(() => {
              location.reload();
            });
          }
        } else if (response?.status === 200) {
          if (ResponseMessage[code]) {
            message.error(ResponseMessage[code]);
          }
        }
      }
    },
  };
}
